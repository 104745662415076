import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Home
const Dashboard = () => import('@/views/Dashboard')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')
const Register = () => import('@/views/pages/Register')

/** ACCESS */
const Permission = () => import('@/views/access/permission.vue')
const Roles = () => import('@/views/access/roles.vue')
const Users = () => import('@/views/access/users.vue')
const AssignedPermission = () => import('@/views/access/assignedpermission.vue')

/** MANTENIMIENTOS */
const Clients = () => import('@/views/mantenance/clients.vue')
const StreetBlocks = () => import('@/views/projects/streetblocks.vue')
const Projects = () => import('@/views/projects/projects.vue')
const Projects_StreetBlock_Lots = () => import('@/views/projects/projects_streetblock_lots.vue')
const Lots  = () => import('@/views/projects/lots.vue')
const Drivers  = () => import('@/views/mantenance/drivers.vue')
const Projects_StreetBlock = ()=> import('@/views/projects/projects_streetblocks.vue')

/** COTIZACION */
const Quotation = () => import('@/views/quotation/index.vue')
/*VISITAS */
const Visits = () => import('@/views/visit/visits.vue')

/** REPORTES */
const Rptvisits = () => import('@/views/reports/reports_visits.vue')
const Rptquotation = () => import('@/views/reports/reports_quotations.vue')
const Rptsales = () => import('@/views/reports/reports_sales.vue')
const Rptpayments = () => import('@/views/reports/reports_payments.vue')
const Rptincomes = () => import('@/views/reports/reports_incomes.vue')


/*** MIS VISITAS */
const MyVisits = () => import('@/views/myvisits/index.vue')

/*** VENTAS DE LOTES */
const SaleLots = () => import('@/views/sale/index.vue')

/*** COBROS DE LOTES */
const ChargeLots = () => import('@/views/charge/index.vue')

Vue.use(Router)

export default new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      redirect: '/login',
      name: '',
      component: {
        render (c) { return c('router-view') } //componente 
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login
        },
      ]
    },
    {
      path: '/home',
      redirect: '/home',
      name: 'Escritorio',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/',
          name: 'Inicio',
          component: Dashboard
        },      
      ]
    },
    {
      path: '/accesos',
      redirect: '/accesos/permisos',
      name: 'Accesos ',
      component: TheContainer,
      children: [
        {
          path: '/accesos/permisos',
          name: 'Permisos de usuario',
          component: Permission
        },
        {
          path: '/accesos/tipos',
          name: 'Roles de usuario',
          component: Roles
        },
        {
          path: '/accesos/usuarios',
          name: 'Usuarios',
          component: Users
        },
        {
          path: '/accesos/permisos_asignados',
          name: 'Permisos Asignados',
          component: AssignedPermission
        },
      ]
    },
    {
      path: '/mantenimientos',
      redirect: '/mantenimientos/clientes',
      name: 'Clientes ',
      component: TheContainer,
      children: [
        {
          path: '/mantenimientos/clientes',
          name: 'Clientes',
          component: Clients // crear component
        },
        {
          path: '/mantenimientos/movilidad',
          name: 'Movilidad',
          component: Drivers //crear component
        },
      ]
    },
    {
      path: '/proyectos',
      redirect: '/proyectos/lote',
      name: 'Proyectos ',
      component: TheContainer,
      children: [
        {
          path: '/proyectos/lote',
          name: 'Lotes',
          component: Lots // crear component
        },
        {
          path: '/proyectos/etapa',
          name: 'Etapas/Manzanas',
          component: StreetBlocks // crear component
        },
        {
          path: '/proyectos/proyectos',
          name: 'Proyectos',
          component: Projects // crear component
        },
        {
          path: '/proyectos/proyecto_manzana',
          name: 'Proyecto Manzana',
          component: Projects_StreetBlock // crear component
        },
        {
          path: '/proyectos/proyecto_manzana_lote',
          name: 'Proyecto Manzana Lotes',
          component: Projects_StreetBlock_Lots // crear component
        },
      ]
    },
    {
      path: '/cotizacion',
      redirect: '/cotizacion',
      name: 'Cotización',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/cotizacion',
          name: '',
          component: Quotation
        },      
      ]
    },
    {
      path: '/visitas',
      redirect: '/visitas',
      name: 'Visitas',
      component: TheContainer, // montar componente
      children: [
        {
          path: '/visitas',
          name: '',
          component: Visits
        },      
      ]
    },
    {
      path: '/reportes',
      redirect: '/reportes/reportes_visitas',
      name: 'Reportes ',
      component: TheContainer,
      children: [
        {
          path: '/reportes/reportes_visitas',
          name: 'Reporte Visitas',
          component: Rptvisits
        },
        {
          path: '/reportes/reportes_cotizaciones',
          name: 'Reporte Cotizaciones',
          component: Rptquotation
        },
        {
          path: '/reportes/reportes_ventas',
          name: 'Reporte Ventas',
          component: Rptsales
        },
        {
          path: '/reportes/reportes_cobros',
          name: 'Reporte Cobros',
          component: Rptpayments
        },
        {
          path: '/reportes/reportes_ingresos',
          name: 'Reporte Ingresos',
          component: Rptincomes
        },
      ]
    },
    {
      path: '/misvisitas',
      redirect: '/misvisitas',
      name: 'Conductor ',
      component: TheContainer,
      children: [
        {
          path: '/misvisitas',
          name: 'Mis visitas',
          component: MyVisits // crear component
        },
      ]
    },
    {
      path: '/ventalotes',
      redirect: '/ventalotes',
      name: 'Venta de lotes ',
      component: TheContainer,
      children: [
        {
          path: '/ventalotes',
          name: 'Venta',
          component: SaleLots // crear component
        },
      ]
    },
    {
      path: '/cobros',
      redirect: '/cobros',
      name: 'Cobros de lotes ',
      component: TheContainer,
      children: [
        {
          path: '/cobros',
          name: 'Cobros',
          component: ChargeLots // crear component
        },
      ]
    },
  ]
}
